import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { faChevronDown, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { ActivatedRoute, Router } from "@angular/router";
import { map, Observable } from "rxjs";
import { onFirstClick } from "../../../@common/DOM/close-on-first-click.function";
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

type ParameterName = 'hits' | 'language' | 'sort' | 'type' | 'year';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: [ './dropdown.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ FaIconComponent, NgIf, NgFor, AsyncPipe ]
})
export class DropdownComponent {
  @Input({required: true}) items: { name: string, value: string }[] = [];
  @Input({required: true}) defaultName: string = '';
  @Input() parameter!: ParameterName;
  @Input() useHits: boolean = false;
  @Output() onClick = new EventEmitter<string>();
  hits$: Observable<'descending' | 'ascending'>;
  selectedName$!: Observable<string>;
  isOpened: boolean = false;
  faChevronDown = faChevronDown;
  faSortUp = faSortUp;
  faSortDown = faSortDown;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.hits$ = this.route.queryParamMap.pipe(
      map(params => params.get('hits') === 'ascending' ? 'ascending' : 'descending')
    );
    this.selectedName$ = this.route.queryParamMap.pipe(
      map(params => params.get(this.parameter) || this.defaultName)
    );
  }

  toggleDropdown(): void {
    this.isOpened = !this.isOpened;

    if (this.isOpened) {
      onFirstClick(() => {
        this.isOpened = false
        this.cdr.detectChanges();
      });
    }
  }

  select({name, value}: { name: string, value: string }): void {
    this.router.navigate([], {queryParams: {[this.parameter]: value}, queryParamsHandling: 'merge'})
    this.defaultName = name;
    this.onClick.emit(value);
  }

  toggleHits(hits: 'ascending' | 'descending'): void {
    this.router.navigate([], {
      queryParams: {hits: hits === 'descending' ? 'ascending' : 'descending'},
      queryParamsHandling: 'merge'
    });
  }

}
