<div class="dropdown">
    <div class="button__parent">
        <button class="dropdown-btn btn" (click)="toggleDropdown()">
            {{ defaultName }}
            <fa-icon [icon]="faChevronDown"/>
        </button>
        <ng-container *ngIf="hits$ | async as hits">
            <div class="sort-btn" (click)="toggleHits(hits)" *ngIf="useHits">
                <fa-icon [icon]="faSortUp" [class.active]="hits === 'ascending'"/>
                <fa-icon [icon]="faSortDown" [class.active]="hits === 'descending'"/>
            </div>
        </ng-container>
    </div>
    <div class="menu" [class.open]="isOpened">
      <div *ngFor="let item of items" class="dropdown-item" [class.active]="defaultName === item.name"
           (click)="select(item)">
            {{ item.name }}
        </div>
    </div>
</div>
